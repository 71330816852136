import React from 'react'
import PropTypes from 'prop-types'

const CardSet = ({children }) => (
  <div css={theme => ({
      margin: '0',
      width: '100%',
      flexFlow: 'row wrap',
      display: 'flex',
      alignItems: 'stretch',
      alignContent: 'space-between',
      justifyContent: 'flex-start'
    })}
  >
    {children}
  </div>
)

CardSet.propTypes = {
  children: PropTypes.node.isRequired
}

CardSet.defaultProps = {
}


const Card = ({ children, title }) => (
    <div
      css={theme => ({
        margin: '10px',
        background: 'rgba(205, 88, 40, 0.35)',
        border: '2px solid' + theme.primary,
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 ' + theme.dark_bg,
        '&:hover': {
          boxShadow: '0 8px 16px 0' + theme.dark_bg
        },
        transition: '0.3s',
        width: '350px'
      })}
    >
      {title.length > 0 &&
      <div>
        <div css={theme => ({
          padding: '0.75em 0.5em',
          })}
        >
          <h2 css={theme => ({
            fontSize: '1.25em',
            margin: '0'
            })}
          >
            {title}
          </h2>
        </div>
        <div css={theme => ({
            margin: '0 auto',
            width: '90%',
            borderBottom: '2px solid ' + theme.primary
            })}
          >
        </div>
      </div>
      }
      <div css={theme => ({
        fontSize: '1em',
        padding: '2px 16px'
        })}
      >
        {children}
      </div>
    </div>
)

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
}

Card.defaultProps = {
  title: ''
}

export { CardSet, Card }
